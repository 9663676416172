import React from 'react';
import {motion} from 'framer-motion';
import {useModernizr} from './ModernizrContext';
import {convertImagePathToWebp} from '../helper/image-helper';

export default function Image({src, alt, ...otherProps}) {
  const {webp} = useModernizr();

  return (
    <motion.img src={webp ? convertImagePathToWebp(src) : src} alt={alt} {...otherProps} />
  )
}
