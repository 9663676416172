import axios from "axios";
import {path, map, prop, pipe} from 'ramda';

const api = {
  getByProvince
};

export default api;

const parseResponse = pipe(
  prop('data'),
  path(['province', 'holidays']),
  map(prop('date'))
);

function getByProvince(year, province="BC"){
  return axios.get(`api/holidays/${province}/${year}`).then(parseResponse);
}