import axios from 'axios';
import {initializeApp} from 'firebase/app';
import {getAuth, signInWithEmailAndPassword, signOut, updatePassword} from 'firebase/auth';

const api = {
  init,
  login,
  logout,
  changePassword,
  getUser,
  getAuth
};
export default api;

let auth;
function init(){
  return axios.post(`api/config/fb`).then(res => {
    initializeApp(res.data);
    auth = getAuth();
  });
}

function login(email, password){
  return signInWithEmailAndPassword(auth, email, password);
}

function logout(){
  return signOut(auth);
}

function changePassword(password){
  return updatePassword(auth.currentUser, password);
}

function getUser(){
  if(!auth){
    return null;
  }

  return auth.currentUser;
}

