import React from 'react';
import ButtonPrimary from '../ButtonPrimary';
import {useAuth} from '../AuthContext';

export default function ChangePassword() {
  const [error, setError] = React.useState();
  const pwdRef = React.useRef('');
  const pwdNewRef = React.useRef('');
  const confirmRef = React.useRef('');
  const {changePassword, login, user} = useAuth();

  const handleUpdate = () => {
    setError('');

    console.log(user);

    if(!pwdNewRef.current.value.trim()){
      setError('Please enter a new password');
      return;
    }

    if(pwdNewRef.current.value !== confirmRef.current.value){
      setError('Password does not match');
      return;
    }

    login(user.email, pwdRef.current.value).then(() => {
      return changePassword(pwdNewRef.current.value).then(() => setError('password has been updated'));
    }).catch(err => setError(err));

  };

  return (
    <div className="flex flex-col">
      <input ref={pwdRef} type="password" placeholder="Current password" />
      <input ref={pwdNewRef} type="password" placeholder="New password" className="mt-2" />
      <input ref={confirmRef} type="password" placeholder="Confirm password" className="mt-2" />
      <ButtonPrimary onClick={handleUpdate} className="mt-2">Update</ButtonPrimary>
      <div className="text-red text-lg mt-5">{error}</div>
    </div>
  )
}
