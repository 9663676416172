import React from 'react';
import {init} from 'ramda';
import {HashLink as Link} from 'react-router-hash-link';

const SubItem = ({item}) => <div><Link to={item.path} className="text-sm whitespace-nowrap">{item.text}</Link></div>

export default function Footer({navItems}) {
  const items = React.useMemo(() => init(navItems), [navItems]);

  return (
    <div className="bg-blue-dark text-white py-10">
      <div className="container mx-auto p-5" style={{maxWidth:'1020px'}}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-center" >
        {
          items.map(d => (
            <div key={d.text} className="mb-5 w-1/3 sm:w-full mx-auto">
              <Link to={d.path} className="uppercase block mb-3 text-lg whitespace-nowrap" >
                {d.text}
              </Link>
              {d.children && d.children.map(sub => <SubItem key={sub.text} item={sub} />)}
            </div>
          ))
        }
        </div>
      </div>
    </div>
  )
}
