import {PATH_HOME, PATH_ABOUT, PATH_SERVICE, PATH_CONTACT, PATH_APPOINTMENT} from './constant';
import {getImagePath} from '../helper/image-helper';
import {getServicePath} from '../helper/path-helper';

const AUTO_GLASS_REPAIR = "Auto Glass Repair";
const STONE_CHIP_CRACK = "Stone Chip & Cracks";
const WINDSHIELD_REPAIR = "Windshield Repair";
const WARRANTY = "Warranty";
const AUTO_GLASS_REPLACEMENT = 'Auto glass replacement';
const INSURANCE_CLAIMS = 'Insurance claims & warranties';
const REPAIR_VS_REPLACEMENT = 'Repair Vs Replacement';
const MOBILE_SERVICE = 'Mobile service';

export const navItems = [
  {
    text: 'Home', 
    path: PATH_HOME
  },
  {
    text: 'About Us',
    path: PATH_ABOUT, 
    children: [
      {text: 'Our Story', path: `${PATH_ABOUT}#OurStory`}, 
      {text: 'Customer Reviews', path: `${PATH_ABOUT}#Reviews`}
    ]},
  {
    text: 'Services', 
    path: PATH_SERVICE, 
    children: [
      {text: AUTO_GLASS_REPAIR, path: getServicePath(AUTO_GLASS_REPAIR)}, 
      {text: STONE_CHIP_CRACK, path: getServicePath(STONE_CHIP_CRACK)}, 
      {text: WINDSHIELD_REPAIR, path: getServicePath(WINDSHIELD_REPAIR)}, 
      {text: WARRANTY, path: getServicePath(WARRANTY)}
    ]
  },
  {
    text: 'Contact Us', 
    path: PATH_CONTACT
  },
  {
    text: 'Online Appointment',
    path: PATH_APPOINTMENT
  }
];

export const testimonials = [
  {name: "Jasmine Grace Reid", testimonial: `BC Auto Glass provided prompt, friendly and professional service to replace the windshield in our car. We were able to get an appointment at our convenience, they showed up on time and had the windshield replaced in under an hour for a very good price. I highly recommend BC auto glass!`},
  {name: "Hyehyun Kim", testimonial: `This place is amazing. I got an appointment right away. Receptionist was so nice and kind!!
  Did the job in less than 2 hours.
  I Definitely going back If a little rock splashes back in my car and cracks back again in my car glass!!`},
  {name: "Anne Robertson", testimonial: `Outstanding service. Reza was so helpful.  He gave us an accurate estimate for both cost and length of service time.  Very highly recommended.`},
  {name: "Khash Jafari", testimonial: `Fantastic customer service and the best prices in Vancouver, I called a bunch of other places to get a quote and these guys were the best and cheapest option. Not only the prices were the best but the work and care they put in is impeccable!`},
  {name: "Captain Cruze", testimonial: `Thank you very much Reza for taking car of my Ford Truck windshield. Looks great and I’m very happy .
  Great job. Cheers`},
  {name: "Pat Deutscher", testimonial: `Very impressed with BC autoglass. Installation was flawless and service was fast, friendly and professional.  Prices were very reasonable too.  I highly recommend Reza and team!`},
  {name: "Regan Wang", testimonial: `Thanks Reza for his prompt and professional service, highly recommended... you will not be disappointed.`},
  {name: "payton yee", testimonial: `SUPER FAST SUPER PROFESSIONAL BEST AUTO GLASS REAPIR !

  Plus you are SUPPORTING LOCAL !
  
  Reza is the best in town !`},
  {name: "Imkyeong Kang", testimonial: `Was here a few days ago, felt like Reza, the owner, was so experienced, professional, and so friendly! I'd definately go back if my car window is broken (knock on the wood)`},
  {name: "Ali Moeeni", testimonial: `They are the best with very reasonable price and knowledgeable`},
];

const getServiceImage = getImagePath('service');

export const services = [
  {
    text: AUTO_GLASS_REPAIR, 
    desc: 'Repairing damaged auto glass is crucial', 
    contents: [
      'Repairing damaged auto glass is crucial. It can be a nuisance driving with a crack or a chip obstructing the view but more importantly, it is illegal and dangerous. Repairing damage to the auto glass is easy when utilizing the skills and experience of the experts at Auto Glass Repair. In addition to the best glass installers doing the job, we will guarantee the premium integrity of the materials used. All the materials used on your vehicle are 100% new.',
      'We have implemented the safest way to replace auto glass as a daily practice. This has resulted in our consumers having 100% satisfaction. Given that our top priorities are safety and consumer satisfaction, we are committed in providing the best service for the best price, on any type of vehicle.'
    ],
    bgImage: getServiceImage('service01.jpg'), 
    icon: getServiceImage('service01_ico.jpg'),
    link: getServicePath(AUTO_GLASS_REPAIR) 
  },
  {
    text: STONE_CHIP_CRACK, 
    desc: 'A windshield damaged by a stone does not always require replacement', 
    contents: [
      'A windshield damaged by a stone does not always require replacement. However, repairing it is a more efficient way to fix the problem, both practically and economically. If your vehicle is equipped with a laminated glass windshield, there are several technical and legal regulations that need to be taken into consideration:',
      'The damage must be taken care of as soon as possible after the accident. This is due to its tendency to accumulate moisture and dirt, which will visibly degrade the quality of the repair possible.',
      "Damage to the outer windshield surface up to 5mm can be repaired. Anything more than is rarely fixable. In addition, the screen inside as well as the intermediate plastic foil must be free from trauma. Minor scratches and small 'pits' caused by grainy sand particles etc. cannot be eliminated by this procedure.",
      "All lines originating from the main point of impact must not be longer than 50mm and not end at the external border of the windshield.",
      "Repairs are not permitted within the driver's field of vision. The definition of driver's field of vision is the 29cm wide strip extending from above the centre of steering wheel, with its top and lateral boundaries being the outermost arc of the wiper (this is a hypothetical line created by the wiper)."
    ],
    bgImage: getServiceImage('service02.jpg'), 
    icon: getServiceImage('service02_ico.jpg'),
    link: getServicePath(STONE_CHIP_CRACK) 
  },
  {
    text: WINDSHIELD_REPAIR, 
    desc: 'Our staff at Mobile Windshield Repair Vancouver will ensure', 
    contents: [
      "Our staff at Mobile Windshield Repair Vancouver will ensure that your windshield is repaired or replaced efficiently. Out technicians' expertise, along with our premium materials and tools, guarantee that your windshield is better than new when we are finished with it. In addition, our resources allow us to get the job done while complying with your specific requests in a timely fashion.",
      "We like to maintain our reputation for fast and quality repairs and therefore all of our customers receive amazing service. This is perhaps the main reason we are successful in keeping our customers content, and always receive glowing reviews."
    ],
    bgImage: getServiceImage('service03.jpg'), 
    icon: getServiceImage('service03_ico.jpg'),
    link: getServicePath(WINDSHIELD_REPAIR)
  },
  {
    text: WARRANTY, 
    desc: 'Mobile Windshield and Auto Glass Repair', 
    contents: [
      "Mobile Windshield and Auto Glass Repair. Our fully trained professionals promise a perfect installation with 100% efficiency and quality rating for as long as you hold or lease your vehicle. If you find your requirements are not being fulfilled, be sure to call and inform us.",
      "What makes Auto Glass Repair special?",
      "Auto Glass Repair will repair your windshield to ensure that it is as safe as before. We take full responsibility for any defects that your auto glass might exhibit for as long as you own the vehicle. If a fault is found, report to us at 604.644.7757. If we find that our work needs improvement, we will fix it free of charge.",
      "* This warranty only covers any necessary replacements and does not include repairs for any accidental damage."
    ],
    bgImage: getServiceImage('service04.jpg'), 
    icon: getServiceImage('service04_ico.jpg'),
    link: getServicePath(WARRANTY)
  },
  {
    text: AUTO_GLASS_REPLACEMENT, 
    desc: 'Our staff will make sure that your windshield is repaired or replaced swiftly', 
    contents: [
      'Our staff at Mobile Auto Glass Replacement will make sure that your windshield is repaired or replaced swiftly. Out technicians\' expertise, along with our premium materials and tools, guarantee that your windshield is better than new when we are finished with it. In addition, our vast resources allow us to get the job done while complying to your specific requests in a timely fashion. We like to maintain our reputation and thus, we give all of our customers an equally amazing windshield changing experience. This is perhaps the main reason we are very successful in keeping our customers content throughout their windshield changing process and receive no negative reviews of our service! Repairing a damaged auto glass is very crucial. It can perhaps be a nuisance driving around with a crack or a chip obstructing the view but more importantly, it is illegal and dangerous. Repairing such a damage to the auto glass is very easy when utilizing the skills and experience of experts at Toronto Auto Glass. In addition to the best glass installers doing the job, we will guarantee the premium integrity of the materials used. Old glass will never be used! All the materials used on your vehicle are 100% brand new! We have implemented the safest way to replace auto glass (according to credible research) in our daily routine. This has resulted in our consumers having 100% satisfaction! As our top priorities are safety and consumer satisfaction, we are committed in providing the best service for the best price! We can repair or replace auto glass of any type! This includes foreign cars, vans, trucks, commercial vehicles, Semi\'s, and many more! We also have the lowest rate on the net due to our vast resources at hand such as numerous auto technicians and dependable auto glass vendors.'
    ],
    bgImage: null, 
    icon: getServiceImage('service05_ico.jpg'),
    link: getServicePath(AUTO_GLASS_REPLACEMENT)
  },
  {
    text: INSURANCE_CLAIMS, 
    desc: 'Mobile Auto Glass Repair is an associate of many insurance companies across Canada', 
    contents: [
      'Mobile Auto Glass Repair is an associate of many insurance companies across Canada. Our commitment to customers produces excellent results, especially in terms of benefits to both customers and insurance companies alike. Due to the size of our corporation and our nationwide reach as well as our mobile services, we offer competitive rates, accessibility and more comprehensive warranties.',
      'Given that we cover about the same size geographic area as a typical insurance company, they prefer to have us in their list of credible suppliers. This, along with the quality of our service, makes us the go-to auto glass repair supplier in the Lower Mainland.'
    ],
    bgImage: null, 
    icon: getServiceImage('service06_ico.jpg'),
    link: getServicePath(INSURANCE_CLAIMS)
  },
  {
    text: REPAIR_VS_REPLACEMENT, 
    desc: 'A vehicle\'s windshield has a very high probability of being damaged in accident', 
    contents: [
      "Mobile Windshield Replacement. A vehicle's windshield has a very high probability of being damaged in accident; something as minor as a small crack is potentially lethal. This is due to the fact that a small crack can quickly spread or perhaps even penetrate the windshield glass deep enough to continue into the inner windshield layer. This inner layer has a plastic composition and is tightly 'sandwiched' between the two denser glass layers. Thus, even a small and seemingly insignificant stone can cause extensive damage.",
      "There are a few criteria to consider:",
      "It is possible for a crack to be repaired using many techniques, but not all are equally effective. Tiny cracks can be fixed using relatively simpler techniques than larger cracks. Normal techniques can be used for fixing cracks up to 3 inches, and sometimes it's possible to fix cracks up to 12 inches. As a rule of thumb, windshields with cracks larger than 12 inches should be replaced and not repaired. Therefore it is wise to repair a crack as soon as possible, before it spreads.",
      "Another factor to consider is the location of the crack. A crack directly in front of the driver should be fixed right away, using state-of-the-art technology because less advanced methods can leave marks behind and are not as safe. If a crack occurs near the edge of the windshield it has a chance of spreading much faster and so it should be repaired as soon as possible as well.",
      "These factors should be taken into account before deciding whether to repair or replace the windshield, but before doing anything it is suggested that you talk to a glass specialist and get their opinion on the matter. If replacement of your auto glass cannot be avoided, do not hesitate to fix it as soon as possible – it might save your life."
    ],
    bgImage: null, 
    icon: getServiceImage('service07_ico.jpg'),
    link: getServicePath(REPAIR_VS_REPLACEMENT)
  },
  {
    text: MOBILE_SERVICE, 
    desc: 'We offers fast and efficient repair service', 
    contents: [
      "Auto Glass Repair offers fast and efficient mobile auto glass repair service. We also offer a pick-up and drop-off option for the convenience of our customers so they don’t have to lose a minute waiting for their vehicle to be repaired. We will arrange a windshield repair or replacement on the day you need, at a time that is convenient for you."
    ],
    bgImage: null, 
    icon: getServiceImage('service08_ico.jpg'),
    link: getServicePath(MOBILE_SERVICE)
  },
];
