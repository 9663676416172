import React from 'react';

import {getImagePath} from '../helper/image-helper';
import TopHero from './TopHero';
import Container from './Container';

export default function PageLayout({imagePath, pageTitle, contentTitle, renderChild1, renderChild2}) {
  const getImage = getImagePath(imagePath);

  return (
    <>
      {imagePath && <TopHero title={pageTitle} image={getImage('top.jpg')} />}
      <Container className="p-5 pb-0">
        <h2 className="text-blue-dark text-center mb-10">{contentTitle}</h2>
        {renderChild1()}
      </Container>
      {renderChild2 && renderChild2()}
    </>
  )
}
