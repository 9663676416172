import React from 'react';
import {ReactComponent as StarIcon} from '../image/star.svg';
import {ReactComponent as QuoteIcon} from '../image/quote.svg';
import {ReactComponent as ChevronRightCircle} from '../image/chevron-right-circle.svg';
import {ReactComponent as ChevronRight} from '../image/chevron-right.svg';
import {ReactComponent as ChevronLeft} from '../image/chevron-left.svg';
import {ReactComponent as Dot} from '../image/dot.svg';
import {ReactComponent as Location} from '../image/location.svg';
import {ReactComponent as Phone} from '../image/phone.svg';
import {ReactComponent as Email} from '../image/email.svg';
import {ReactComponent as Person} from '../image/person.svg';
import {ReactComponent as Car} from '../image/car.svg';
import {ReactComponent as Magnifier} from '../image/magnifier.svg';
import {ReactComponent as Close} from '../image/close.svg';

const getIconComponent = name =>  
  name === "star"
  ? StarIcon
  : name === "quote"
  ? QuoteIcon
  : name === "chevron-right-circle"
  ? ChevronRightCircle
  : name === "chevron-right"
  ? ChevronRight
  : name === "chevron-left"
  ? ChevronLeft
  : name === "dot"
  ? Dot
  : name === "location"
  ? Location
  : name === "phone"
  ? Phone
  : name === "email"
  ? Email
  : name === "person"
  ? Person
  : name === "car"
  ? Car
  : name === "magnifier"
  ? Magnifier
  : name === "close"
  ? Close
  : <span></span>;

const getStyle = (width, color) => {
  const css = {display:'inline-block', width: width};
  if(color){
    css.fill = color;
  }
  return css;
};;

export default function Icon({name, style, color, width='20px', ...otherProps}) {
  const Component = getIconComponent(name);

  return (
    <div style={{...getStyle(width, color), ...style}} {...otherProps}>
      <Component />
    </div>
  )
}
