import React from 'react';
import {Redirect} from 'react-router-dom';

import {useAuth} from '../AuthContext';
import PageLayout from '../PageLayout';
import Dashboard from './Dashboard';

export default function AdminPage() {
  const {user} = useAuth();
  
  if(!user){
    return <Redirect to="/Login" />;
  }

  return (
    <PageLayout 
      imagePath="about"
      pageTitle="Admin Dashboard"
      contentTitle=""
      renderChild1={Dashboard}
    />
  );
}
