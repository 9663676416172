import React from 'react';
import {getImagePath} from '../helper/image-helper';
import Image from './Image';

const getImage = getImagePath();
const logoImage = getImage('logo.png');

const style = {
  logo: {width: '150px'}
};

export default function Logo() {
  return (
    <div className="mt-4 mb-2 ml-2">
      <Image src={logoImage} alt="logo" style={style.logo} className="w-1/2 sm:w-full" />
    </div>
  )
}
