import React from 'react';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const itemMotion = {
  initial: {opacity: 1},
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5
    }
  },
};

const defaultGridStyle = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-16";

export default function GridItems({items, render, className}) {
  const [ref, inView] = useInView({triggerOnce: true, rootMargin: '-200px 0px'});

  return (
    <motion.div ref={ref} variants={itemMotion} initial="initial" animate={inView ? "animate" : "initial"} className={className ? className : defaultGridStyle}>
      {items.map((d, i) => render(d))}
    </motion.div>
  );
}
