import React from 'react';
import {motion, useViewportScroll, useTransform, useSpring} from 'framer-motion';

export default function Parallax({children, offset=50}) {
  const [elementTop, setElementTop] = React.useState(0);
  const [clientHeight, setClientHeight] = React.useState(0);
  const ref = React.useRef(null);

  const {scrollY} = useViewportScroll();

  const initial = elementTop - clientHeight;
  const final = elementTop + offset;

  const yRange = useTransform(scrollY, [initial, final], [offset, -offset]);
  const y = useSpring(yRange, {stiffness: 400, damping: 90});

  React.useLayoutEffect(() => {
    const element = ref.current;
    const onResize = () => {
      setElementTop(element.getBoundingClientRect().top + window.scrollY ||  window.pageYOffset);
      setClientHeight(window.innerHeight);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref]);

  return (
    <motion.div ref={ref} style={{y}}>
      {children}
    </motion.div>
  );
}
