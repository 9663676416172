import React from 'react';
import {Switch, Route} from 'react-router-dom';

import {navItems} from '../helper/data';
import Header from './Header';
import Footer from './Footer';
import AboutUsPage from './AboutUsPage';
import ServicePage from './ServicePage';
import ContactUsPage from './ContactUsPage';
import AppointmentPage from './AppointmentPage';
import HomePage from './HomePage';
import AdminPage from './admin/AdminPage';
import LoginPage from './LoginPage';

export default function RouterRoot() {
  return (
    <>
      <Header navItems={navItems} />
      <Switch>
        <Route path="/About">
          <AboutUsPage />
        </Route>
        <Route path="/Service/:text?">
          <ServicePage />
        </Route>
        <Route path="/Contact">
          <ContactUsPage />
        </Route>
        <Route path="/Appointment">
          <AppointmentPage />
        </Route>
        <Route path="/Admin">
          <AdminPage />
        </Route>
        <Route path="/Login">
          <LoginPage />
        </Route>
        <Route path="/" exact>
          <HomePage />
        </Route>
      </Switch>
      <Footer navItems={navItems} />
    </>
  )
}
