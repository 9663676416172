import React from 'react';
import {motion, useCycle} from 'framer-motion';

import NavToggle from './NavToggle';
import Navs from './Navs';

const navMotion = {
  open: {width: 250, height: '100vh', transition: {duration: 0.5}},
  closed: {width: 0, transition: {delay: 0.5}},
  hover: {}
};

const style = {
  root: {},
  nav: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    background: '#232323',
  },
  toggle: {position: 'absolute', top: 20, right: 0, zIndex: 10},
  navMenu: {height: '100%', paddingTop:'80px'}
};

export default function TopNavMobile({items}) {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <div style={style.root}>
      <motion.nav 
        initial={false} 
        animate={isOpen ? "open" : "closed"} 
        variants={navMotion} 
        style={style.nav}
      >
        <div style={style.toggle}>
          <NavToggle onClick={toggleOpen} />
        </div>
        <div style={style.navMenu}>
          <Navs navItems={items} />
        </div>
      </motion.nav>
    </div>
  )
}
