import React from 'react';
import {useParams} from 'react-router-dom';
import {find} from 'ramda';

import {services} from '../helper/data';
import {scrollTo} from '../helper/util';
import Container from './Container';
import ServiceCardList from './ServiceCardList';

const style={
  detailHeader: {borderBottom: '3px solid var(--blue)', paddingBottom:'0.8rem', marginBottom: '2rem', textAlign: 'center'}
};

const Detail = ({data}) => (
  <div className="p-5">
    <div className="flex justify-center">
      <h2 style={style.detailHeader}>{data.text}</h2>
    </div>
    {data.contents.map((d, i) => <p key={i} className="font-bold text-xl">{d}</p>)}
  </div>
);

export default function ServiceDetail() {
  const [selected, setSelected] = React.useState();
  const {text} = useParams();

  React.useEffect(() => {
    if(text){
      setSelected(find(d => d.text === text, services));
      scrollTo(0, 'detail');
    }
  }, [text]);

  const handleClick = (e, data) => {
    setSelected(data);
    scrollTo(e.pageY, 'detail');
  };

  return (
    <div className="bg-gray-light pb-10">
      <Container size="lg">
        <ServiceCardList showBgImage={false} showBottomLine={false} onClick={handleClick} />
        <div id="detail">
          {selected && <Detail data={selected}/>}
        </div>
      </Container>
    </div>
  )
}
