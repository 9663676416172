import React from 'react';

import ContentLayout from './ContentLayout';

const contents = [
  "Our top priority is providing the best service in auto glass/windshield repair or replacement. That is why all of our technicians have had extensive training and experience.",
  "We offer a lifetime guarantee for workmanship and leaks pertaining to our auto glass replacement and repairs.",
  "Auto Glass Repair is stays current with innovative auto technologies. In particular, we specialize in vehicles with rain and light sensors. Your vehicle’s glass will be replaced most efficiently as specified by the car manufacturer's requirements.",
  "All of the attributes of our technicians ensure that the job will be done correctly, professionally, and on time!"
];

export default function ServiceContent() {
  return (
    <ContentLayout 
      imagePath="service"
      title="Our Services"
      contents={contents}
    />
  );
}
