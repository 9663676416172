import React from 'react';

import {getStaggerMotion} from '../helper/motion';
import MotionComponent from './MotionComponent';

export default function MotionStaggerChildren({children, delay=0, staggerChildren=0.5, delayChildren=0, ...otherProps}) {
  const staggerMotion = getStaggerMotion({animate: {transition: {delay, staggerChildren, delayChildren}}});
  return (
    <MotionComponent variants={staggerMotion} {...otherProps}>
      {children}
    </MotionComponent>
  )
}
