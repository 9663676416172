import React from 'react';
import {motion} from 'framer-motion';


const path1 = {
  closed: {d: "M 2 2.5 L 20 2.5"},
  open: {d: "M 3 16.5 L 17 2.5"},
  hover: {stroke: 'var(--blue)'}
};
const path2 = {
  closed: {opacity: 1},
  open: {opacity: 0},
  hover: {stroke: 'var(--blue)'}
};
const path2Transition = {duration: 0.1};
const path3 = {
  closed: {d: "M 2 16.346 L 20 16.346"},
  open: {d: "M 3 2.5 L 17 16.346"},
  hover: {stroke: 'var(--blue)'}
};

const style = {
  btn: {
    outline: 'none',
    border: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    background: 'transparent'
  }
};

const Path = props => (
  <motion.path 
    fill="transparent"
    strokeWidth="3"
    stroke="white"
    strokeLinecap="round"
    {...props}
  />
);

export default function NavToggle({onClick}) {
  return (
    <button style={style.btn} onClick={onClick}>
      <motion.svg width="23" height="23" viewBox="0 0 23 23">
        <Path variants={path1} />
        <Path variants={path2} d="M 2 9.423 L 20 9.423" transition={path2Transition} />
        <Path variants={path3} />
      </motion.svg>
    </button>
  )
}
