import React from 'react';


import {getImagePath} from '../helper/image-helper';
import TopHero from './TopHero';
import ContactLocation from './ContactLocation';
import ContactForm from './ContactForm';

const getImage = getImagePath("contact");

export default function ContactUsPage() {
  return (
    <>
      <TopHero title="Contact Us" image={getImage('top.jpg')} paddingBottom="0" />
      <ContactLocation />
      <ContactForm />
    </>
  );
}
