import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {sendMessage} from '../api/contact-api';
import FormikField, {ErrorSubmit} from './FormikField';
import ButtonPrimary from './ButtonPrimary';
import Input from './Input';
import Textarea from './Textarea';

const style = {
  message: {minHeight: '150px'}
};

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: ""
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string(),
  message: Yup.string().required('Required'),
});

export default function ContactForm() {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  function handleSubmit(values){
    sendMessage(values).then(() => {
      window.alert("Thank you. We get your message.");
    }).catch(err => window.alert(err));
  }

  return (
    <div className="p-4 sm:p-10">
      <h2 className="mb-4">Contact us</h2>
      <p className="mb-10 text-xl font-bold">We are happy to answer any questions you may have. Simply complete the below form.</p>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <FormikField id="name" name="name" placeholder="Your Name *" className="w-full" formik={formik} render={Input} />
            </div>
            <div className="mb-4">
              <FormikField id="email" name="email" placeholder="Your Email *" className="w-full" formik={formik} render={Input} />
            </div>
            <div>
              <FormikField id="phone" name="phone" placeholder="Your Phone" className="w-full" formik={formik} render={Input} />
            </div>
          </div>
          <div>
            <FormikField id="message" name="message" placeholder="Message *" className="w-full h-full" style={style.message} formik={formik} render={Textarea}/>
          </div>
        </div>
        <div className="text-right mt-4">
          <ButtonPrimary type="submit">SEND MESSAGE</ButtonPrimary>
        </div>
        <ErrorSubmit formik={formik}/>
      </form>
    </div>
  )
}
