import React from 'react';

import authApi from '../api/auth-api';

const AuthContext = React.createContext();

const AuthProvider = ({children}) => {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    authApi.init().then(() => {
      authApi.getAuth().onAuthStateChanged(setUser);
    });
  }, []);

  const login = (email, password) => authApi.login(email, password);
  const logout = () => authApi.logout().catch(err => console.log(err));
  const changePassword = (password) => authApi.changePassword(password);
    
  return (
    <AuthContext.Provider value={{login, logout, changePassword, user, setUser}}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if(!context){
    throw new Error("useAuth should be used within AuthProvider");
  }

  return context;
};

export {useAuth, AuthProvider};

