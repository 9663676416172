import React from 'react';
import * as R from 'ramda';
import {motion} from 'framer-motion';
import {useWindowSize} from 'react-use';
import {useInView} from 'react-intersection-observer';

import {testimonials} from '../helper/data';
import {getTopDownMotion, getFadeInMotion} from '../helper/motion';
import Icon from './Icon';
import Container from './Container';
import Row from './Row';
import SplitLetter from './SplitLetter';

const TestimonialCard = ({name, testimonial}) => (
  <div className="p-10 pb-16 bg-white shadow-md">
    <div className="flex justify-between">
      <h6 className="mb-3">{name}</h6>
      <div>{testimonial && <Icon name="quote" width="18px" />}</div>
    </div>
    <div className="font-bold">{testimonial}</div>
  </div>
);

const ArrowHandle = ({onClick, icon}) => (
  <div className="bg-blue-dark flex justify-center items-center p-1 cursor-pointer" onClick={onClick}><Icon name={icon} style={{fill:'white'}} /></div>
);

const SIZE_SM = 640;
const topDown = getTopDownMotion();
const fadeIn = getFadeInMotion({animate: {transition: {delay: 0.7}}});

export default function Testimonial() {
  const [index, setIndex] = React.useState(0);
  const {width} = useWindowSize();
  const [ref, inView] = useInView({rootMargin: '-200px 0px', triggerOnce: true});

  const getDisplayCount = () => width < SIZE_SM ? 1 : 2;

  const handleClickLeft = () => setIndex(prev => {
    const next = prev - getDisplayCount();
    return next < 0 ? testimonials.length - getDisplayCount() : next;
  });
  const handleClickRight = () => setIndex(prev => {
    const next = prev + getDisplayCount();
    return next >= testimonials.length ? 0 : next;
  });

  return (
    <div>
      <motion.div ref={ref} variants={topDown} initial="initial" animate={inView ? "animate" : "initial"} className="bg-blue-gray pt-10 px-4">
          <Container size="lg">
            <Row className="mb-12">
              <div className="grid grid-cols-1 sm:grid-cols-2 items-center">
                <motion.h2 className="text-white font-normal mr-3" variants={fadeIn}>Customer Review</motion.h2>
                <div>
                  <SplitLetter delay={1} stagger={0.2}>
                    {R.range(0, 5).map(n => <Icon key={n} name="star" width="24px" />)}
                  </SplitLetter>
                </div>
              </div>
              <Row>
                <ArrowHandle icon="chevron-left" onClick={handleClickLeft}/>
                <div className="px-2">&nbsp;</div>
                <ArrowHandle icon="chevron-right" onClick={handleClickRight}/>
              </Row>
            </Row>
            <motion.div variants={fadeIn} className="grid grid-cols-1 sm:grid-cols-2 gap-3" style={{height: '150px'}}>
            {
              testimonials.slice(index, index + getDisplayCount()).map((d, i) => <TestimonialCard key={i} name={d.name} testimonial={d.testimonial} />)
            }
            </motion.div>
          </Container>
      </motion.div>
      <div className="bg-white" style={{height:'100px'}}></div>
    </div>
  )
}
