import React from 'react';
import Icon from './Icon';

const style = {
  map: {minHeight: '400px'}
};

const ContactInfo = ({icon, iconWidth="20px", children}) => (
  <>
    <div className="mt-8"><Icon name={icon} width={iconWidth}/></div>
    <div className="text-xl font-bold">{children}</div>
  </>
);

export default function ContactLocation() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
      <div className="text-center p-20 bg-gray-light">
        <div><h2>BC</h2></div>
        <div><h2>Auto Glass LTD</h2></div>
        <ContactInfo icon="location" iconWidth="26px">
          <div className="text-orange">131 - 1655 Broadway St.</div>
          <div className="text-orange">Port Coquitlam</div>
        </ContactInfo>
        <ContactInfo icon="phone">
          <a href="tel:604-644-7757" className="hover-primary">604-644-7757</a>
        </ContactInfo>
        <ContactInfo icon="email" iconWidth="24px">
          <a href="mailto:info@bcautoglass.ca" className="hover-primary">info@bcautoglass.ca</a>
        </ContactInfo>
      </div>  
      <div style={style.map}>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2604.5939419581046!2d-122.7659493841362!3d49.24618738127645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d71e6111f213%3A0xafc994cbcc73806!2sBC%20autoglass!5e0!3m2!1sen!2sca!4v1631087944650!5m2!1sen!2sca" width="100%" height="100%" style={{border:0}}></iframe>
      </div>
    </div>
  );
}
