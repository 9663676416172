import React from 'react';
import {motion} from 'framer-motion';

const motionList = {
  open: {width: '100%', display:'inline-block', transition: {staggerChildren: 0.07, when: 'beforeChildren'}},
  closed: {width: 0, display: 'none', transition: {staggerChildren: 0.04, staggerDirection: -1, when: 'afterChildren'}}
};

const motionItem = {
  open: {opacity: 1, y: 0},
  closed: {opacity: 0, y: -30}
};

export default function Navs({navItems}) {
  return (
      <motion.ul variants={motionList} className="md:flex md:justify-around">
        {navItems && navItems.map(i => <motion.li variants={motionItem} className="ml-8 mt-4 md:ml-0 md:mt-0" key={i.text}><a href={i.path} className="text-white">{i.text}</a></motion.li>)}
      </motion.ul>
  );
}
