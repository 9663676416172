import React from 'react';
import {motion} from 'framer-motion';

import {getTopDownMotion} from '../helper/motion';
import {getImagePath} from '../helper/image-helper';
import MotionStaggerChildren from './MotionStaggerChildren';
import Image from './Image';
const topDownMotion = getTopDownMotion({initial: {y: -30}});

export default function ContentLayout({id, imagePath, title, contents}) {
  
  const getImage = getImagePath(imagePath);

  return (
    <>
      <h2 className="mb-6" id={id || title}>{title}</h2>
      <div className="grid md:grid-cols-2">
        <div>
          <MotionStaggerChildren staggerChildren={0.2}>
            {contents.map((d, i) => <motion.p key={i} variants={topDownMotion} className="mb-3">{d}</motion.p>)}
          </MotionStaggerChildren>
        </div>
        <div>
          <Image src={getImage("contents01.jpg")} className="object-cover h-full" />
        </div>
      </div>
    </>
  )
}
