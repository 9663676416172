import React from 'react';
import {motion, useViewportScroll, useTransform} from 'framer-motion';
import Image from './Image';

const style = {
  image: {
    objectFit: 'cover',
    width: '100%',
    zIndex: -1,
  }
};

export default function ParallaxImage({image, height="100vh", children}) {
  const {scrollY} = useViewportScroll();
  const y = useTransform(scrollY, [0, 1], [0, -0.3], {clamp: false});

  return (
    <div className="relative">
      {
        <Image src={image} style={{...style.image, height:height}} alt="" />
      }
      <motion.div className="absolute top-0 left-0 w-full flex items-center justify-center h-full" style={{y}}>
        {children}
      </motion.div>
    </div>
  )
}
