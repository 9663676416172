import React from 'react';
import {useModernizr} from './ModernizrContext';
import {isImageFile, convertImagePathToWebp} from '../helper/image-helper';

export default function BgImage({src, className, children}) {
  const {webp} = useModernizr();
  const url = isImageFile(src) && webp ? convertImagePathToWebp(src) : src;

  return (
    <div className={`bg-orgin-border bg-no-repeat bg-cover bg-center ${className ? className : ''}`} style={{backgroundImage: `url(${url})`}}>
      {children}
    </div>
  );
}
