import React from 'react';

import OurStory from './OurStory';
import AboutTestimonials from './AboutTestimonials';
import PageLayout from './PageLayout';

export default function AboutUsPage() {
  return (
    <PageLayout 
      imagePath="about"
      pageTitle="About Us"
      contentTitle="Welcome to BC Auto Glass"
      renderChild1={OurStory}
      renderChild2={AboutTestimonials}
    />
  );
}
