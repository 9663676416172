import React from 'react';

const getWidth = size => 
  size === "lg" 
  ? "1200px" 
  : size === "md"
  ? "800px"
  : size === "sm"
  ? "400px"
  : "1020px"

export default function Container({style, className, size, children}) {
  return (
    <div className={`container mx-auto ${className ? className : ''}`} style={{maxWidth: getWidth(size), ...style}}>
      {children}
    </div>
  )
}
