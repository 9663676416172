import React from 'react';
import {useHistory} from 'react-router-dom';

import {getServicePath} from '../helper/path-helper';
import Container from './Container';
import Center from './Center';
import ServiceCardList from './ServiceCardList';

export default function OurService() {
  const history = useHistory();
  const handleClick = (e, data) => history.push(getServicePath(data.text));
  return (
    <Container size="lg" className="relative">
      <Center>
        <h2 className="mb-16 whitespace-nowrap">Discover Our Services</h2>
      </Center>
      <ServiceCardList top={4} showBottomLine={true} showBgImage={true} onClick={handleClick} />
    </Container>
  )
}
