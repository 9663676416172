export const scrollTo = (initY, elementId) => {
  const target = document.getElementById(elementId);
  let curY = initY;
  const scrollToInner = () => {
    curY += 40;
    window.scrollTo(0, curY);
    if(curY < target.offsetTop){
      setTimeout(scrollToInner, 10);
    }
  }; 
  return scrollToInner();
};