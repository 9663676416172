import React from 'react';

import ContentLayout from './ContentLayout';

const story = [
  "Auto Glass Repair has been in business for over 15 years. We are one of BC’s leading vehicle glass repair and replacement companies, serving thousands of clients every year – 24 hours a day, 7 days a week, 365 days a year.",
  "Whether you choose the convenience of getting one of our mobile technicians to come to you at home, work or wherever you are to have us fix your auto glass problem, or you wish to bring your vehicle to us, you can relax in the knowledge that all our workmanship is guaranteed for as long as you own the vehicle.",
  "We only use glass manufactured to OEM standards, whether sourced from original equipment manufacturers or other suppliers, ensuring that the glass we fit is as good as the original piece of glass we are replacing.",
  "What's more, we have strong relationships with most of British Columbia’s major insurance companies that enables us to manage all your insurance paperwork for you if you are claiming for the work on your vehicle insurance. Providing you the best customer services and quality workmanship is our highest priority."
];

export default function OurStory() {
  return (
    <ContentLayout 
      id="OurStory"
      imagePath="about"
      title="Our Story"
      contents={story}
    />
  )
}
