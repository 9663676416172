import React from 'react';
import { includes, filter } from 'ramda';
import Dropdown from './Dropdown';

export default function TimeSelect({hours, takenHours, value, onChange, ...otherProps}) {
  const availabeHours = React.useMemo(() => filter(h => !includes(h, takenHours), hours), [hours, takenHours]);
  const handleChange = e => onChange(e.target.value);

  React.useEffect(() => {
    onChange(availabeHours[0]);
  }, [availabeHours]);

  return (
    <Dropdown value={value} items={availabeHours} onChange={handleChange} {...otherProps} />
  );
}
