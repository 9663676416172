import React from 'react';

import {getImagePath} from '../helper/image-helper';
import Image from './Image';

const getImage = getImagePath('main');
const icbcLogo = getImage('icbc_logo.png');

const style = {
  icbcLogo: {maxWidth: '180px', height: "45px"}
};

export default function IcbcLogo(props) {
  return (
    <Image src={icbcLogo} alt="icbc logo" style={style.icbcLogo} {...props} />
  );
}
