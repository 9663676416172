import React from 'react'

export default function Grid({className, gap="4", children}) {
  let cssClass = "grid grid-cols-1 md:grid-cols-2 gap-" + gap;
  if(className){
    cssClass += (" " + className);
  }
  return (
    <div className={cssClass}>
      {children}
    </div>
  )
}
