import React from 'react';
import {motion} from 'framer-motion';

import {getTopDownMotion} from '../helper/motion';
import Avatar from './Avatar';

const topDownMotion = {...getTopDownMotion()};
const motionHover = {scale: 1.1};

export default function ServiceCard({data, showBgImage, showBottomLine, onClick}) {
  const {text, desc, bgImage, icon} = data;
  
  let rootCss = showBgImage || showBottomLine ? {} : null;
  if(showBottomLine){
    rootCss['borderBottom'] = '6px solid var(--blue)';
  }
  if(showBgImage){
    rootCss['backgroundImage'] = `url(${bgImage})`;
  }

  return (
    <motion.div 
      variants={topDownMotion} 
      className="bg-origin-border bg-cover bg-no-repeat py-8 px-10 text-center cursor-pointer" 
      style={rootCss}
      whileHover={motionHover}
      onClick={e => onClick ? onClick(e, data) : null}
    >
      <div className="flex justify-center"><Avatar image={icon} width={80} /></div>
      <h4 className="mt-5">{text}</h4>
      <div className="mt-2 font-bold">{desc}</div>
    </motion.div>
  );
}
