import React from 'react';
import {take} from 'ramda';

import {services} from '../helper/data';
import ServiceCard from './ServiceCard';
import GridItems from './GridItems';

export default function ServiceCardList({top=-1, showBgImage=true, showBottomLine=false, onClick}) {
  return <GridItems 
    items={top < 0 ? services : take(top, services)}
    render={d => <ServiceCard key={d.text} data={d} showBottomLine={showBottomLine} showBgImage={showBgImage} onClick={onClick} />}
  />;
}
