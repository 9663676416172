import axios from 'axios';
import {formatDate} from '../helper/datetime-helper';

const url = 'api/appointments';

const api = {
  get,
  getById,
  check,
  add,
  cancel,
  update
};
export default api;

function get(start, end){
  const dateStart = formatDate(start || new Date());
  const dateEnd = end ? formatDate(end) : null;
  let endpoint = `${url}/bydate/${dateStart}`;
  if(dateEnd){
    endpoint += `/${dateEnd}`;
  }
  return axios.get(endpoint);
}

function getById(id){
  return axios.get(`${url}/byid/${id}`);
}


function add(data){
  return axios.post(url, data);
}

function check(data){
  return axios.post(`${url}/check`, data);
}

function cancel(data){
  return axios.post(`${url}/cancel`, data);
}

function update(data){
  return axios.post(`${url}/update`, data);
}
