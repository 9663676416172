import React from 'react';
import {Redirect} from 'react-router-dom';

import {useAuth} from './AuthContext';
import PageLayout from './PageLayout';
import ButtonPrimary from './ButtonPrimary';

const style = {
  login: {height: '600px'}
};

function Login() {
  const userRef = React.useRef();
  const passwordRef = React.useRef();
  const {login, user} = useAuth();

  const handleSignin = () => login(userRef.current.value, passwordRef.current.value).catch(err => console.log(err));

  const handleKeyUp = e => {
    if(e.key === 'Enter'){
      handleSignin();
    }
  };

  if(user){
    return <Redirect to="/Admin" />;
  }

  return (
    <div className="flex justify-center items-center h-96 min-w-screen" style={style.login}>
      <div>
        <div>
          <input ref={userRef} type="text" placeholder="Email" className="w-80" />
        </div>
        <div>
          <input ref={passwordRef} type="password" placeholder="Password" className="w-80 mt-2" onKeyUp={handleKeyUp}/>
        </div>
        <ButtonPrimary type="submit" className="mt-5 w-full" onClick={handleSignin}>Sign in</ButtonPrimary>
      </div>
    </div>
  );
}

export default function LoginPage() {
  return (
    <PageLayout 
      imagePath=""
      pageTitle="Sign In"
      contentTitle=""
      renderChild1={Login}
    />
  );
}
