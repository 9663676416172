import axios from 'axios';

const api = {
  upload
};

export default api;

function upload(data, prefix){
  const formData = new FormData();
  data.forEach(file => formData.append(file.name, file));
  return axios.post(`api/upload/${prefix}`, formData);
}