import React from 'react';
import {motion} from 'framer-motion';

import ParallaxImage from './ParallaxImage';
import SplitLetter from './SplitLetter';

export default function TopHero({title, image, height="60vh", paddingBottom="30px", children}) {
  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <ParallaxImage image={image} height={height}>
        {
          title &&
          // <MotionTopDown className="text-white whitespace-nowrap text-center">
          //   <h1>{title}</h1>
          // </MotionTopDown>
          <SplitLetter className="text-white whitespace-nowrap text-center" wrapper="h1">
            {title}
          </SplitLetter>
        }
        {children}
      </ParallaxImage>
      {
        paddingBottom &&
        <div style={{height: paddingBottom}}></div>
      }
    </motion.div>
  )
}
