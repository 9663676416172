import React from 'react';
import {motion} from 'framer-motion';

const getSentence = (delay, stagger) => ({
  initial: {
    //opacity: 1, 
    //y: -100
  },
  animate: {
    //opacity: 1,
    //y: 0,
    transition: {
      delay: delay,
      delayChildren: delay,
      staggerChildren: stagger
    }
  }
});

const letter = {
  initial: {
    opacity: 0,
    y:-10,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1
    }
  }
};

const isSpace = ch => ch === ' ';

export default function SplitLetter({children, wrapper="h3", delay=0, stagger=0.1, ...otherProps}) {
  const sentence = getSentence(delay, stagger);
  const MotionComponent = motion[wrapper];
  const letters = typeof(children) === 'string' ? children.split("") : children;
  return (
    <MotionComponent variants={sentence} {...otherProps}>
      {letters && letters.map((ch, i) => <motion.span key={`${ch}-${i}`} variants={letter}>{isSpace(ch) ? <span>&nbsp;</span> : ch}</motion.span>)}
    </MotionComponent>
  )
}
