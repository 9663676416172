import React from 'react';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

import {getFadeInMotion, getTopDownMotion} from '../helper/motion';
import {getImagePath} from '../helper/image-helper';
import SplitLetter from './SplitLetter';
import BgImage from './BgImage';
import Icon from './Icon';

const getImage = getImagePath('main');

const whyBcAutoglass = [
  "Over 20 years experience",
  "High customer satisfaction",
  "Dealing with ICBC and all Insurance companies",
  "Life time warranty",
  "Locally owned & operated"
];

const fadeIn = getFadeInMotion();
const fadeInList = getFadeInMotion({animate: {transition: {delayChildren: 1.2, staggerChildren: 0.5}}});
const topDown = getTopDownMotion();

export default function WhyBcAuto() {
  const [ref, inView] = useInView({rootMargin: '-200px 0px', triggerOnce: true});

  return (
    <BgImage src={getImage('whybc.jpg')}>
        <motion.div ref={ref} variants={fadeIn} initial="initial" animate={inView ? "animate" : "initial"} className="p-6 sm:p-36">
          <div className="mb-5"><SplitLetter stagger={0.05} className="whitespace-nowrap">Why BC Auto Glass?</SplitLetter></div>
          <motion.ul variants={fadeInList}>
            {whyBcAutoglass.map(d => <motion.li key={d} variants={topDown}><h5 className="font-normal"><Icon name="dot" size="14px" color="var(--blue)" /> {d}</h5></motion.li>)}
          </motion.ul>
        </motion.div>
    </BgImage>
  );
}
