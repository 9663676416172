import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

const LoadingOverayContext = React.createContext();

const LoadingOverayProvider = ({children}) => {
  const [active, setActive] = React.useState(false);
  const value = {showLoading: setActive};

  return (
    <LoadingOverayContext.Provider value={value}>
      <LoadingOverlay active={active} spinner>
        {children}
      </LoadingOverlay>
    </LoadingOverayContext.Provider>
  );
};

const useLoadingOveray = () => {
  const context = React.useContext(LoadingOverayContext);
  if(!context){
    throw Error("useLoadingOveray should be used inside of LoadingOverayProvider");
  }

  return context;
};

export {LoadingOverayProvider, useLoadingOveray};