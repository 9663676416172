import React from 'react';

import css from './RadioButton.module.css';

export default function RadioButton({id, name, label, value, className, ...otherProps}) {
  return (
    <label className={`${css.radio} ${className}`}>
      <span className={css.radio__input}>
        <input type="radio" id={id} name={name} value={value} {...otherProps} /> 
        <span className={css.radio__control}></span>
      </span>
      <label className={css.radio__label} htmlFor={id}>{label || value}</label>
    </label>
  );
}
