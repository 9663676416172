import React from 'react';
import {take} from 'ramda';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

import {getTopDownMotion} from '../helper/motion';
import {testimonials} from '../helper/data';
import Icon from './Icon';
import Container from './Container';
import MotionStaggerChildren from './MotionStaggerChildren';

const childMotion = getTopDownMotion({initial: {transition: {y:-100}}});

const TestimonialSection = ({data}) => (
  <motion.div variants={childMotion}>
    <Icon name="quote" width="16px" color="white" />
    <p>{data.testimonial}</p>
    <h6 className="text-right mr-5">{data.name}</h6>
  </motion.div>
);

export default function AboutTestimonials() {
  const [ref, inView] = useInView({triggerOnce: true, rootMargin: '-200px 0px'});

  return (
    <div  id="Reviews" ref={ref}>
      <MotionStaggerChildren animate={inView ? "animate" : "initial"} className="bg-blue-lighter text-white" staggerChildren={0.2}>
        <Container className="p-5">
          <h3 className="mb-5">Customer Reviews</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {take(9, testimonials).map((d, i) => <TestimonialSection data={d} key={i} />)}
          </div>
        </Container>
      </MotionStaggerChildren>
    </div>
  );
}
