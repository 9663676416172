import React from 'react';

import HomeHero from './HomeHero';
import OurService from './OurService';
import WhyBcAuto from './WhyBcAuto';
import Testimonial from './Testimonial';
import OurWork from './OurWork';

export default function HomePage() {
  return (
    <div>
        <HomeHero />
        <OurService />
        <WhyBcAuto />
        <Testimonial />
        <OurWork />
    </div>
  )
}
