import '@natscale/react-calendar/dist/main.css';

import React from 'react';
import {sort, head, filter, map} from 'ramda';
import { Calendar } from '@natscale/react-calendar';

import api from '../../api/appointment-api';
import {formatDate, firstDateOfMonth, addDays} from '../../helper/datetime-helper';
import Grid from '../Grid';
import BookingList from './BookingList';
import BookingDetail from './BookingDetail';
import ButtonDanger from '../ButtonDanger';
import ButtonPrimary from '../ButtonPrimary';
import {useAuth} from '../AuthContext';
import ModalBox from '../ModalBox';
import ChangePassword from './ChangePassword';

const style = {
  modal: {maxWidth:'300px', maxHeight: '300px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'},
  modalClose: {top:'10px', right: '20px'}
};

const byBookingDateTime = (a, b) => 
  a.bookingDate > b.bookingDate 
  ? 1 
  : a.bookingDate < b.bookingDate 
  ? -1 
  : a.bookingTime > b.bookingTime
  ? 1
  : a.bookingTime < b.bookingTime
  ? -1
  : 0;

export default function Dashboard() {
  const [allBookings, setAllBookings] = React.useState([]);
  const [bookings, setBookings] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [dateStart, setDateStart] = React.useState(new Date());
  const [firstDate, setFirstDate] = React.useState(null);
  const [allBookingDates, setAllBookingDates] = React.useState([]);
  //const [dateEnd, setDateEnd] = React.useState(null);
  const {logout} = useAuth();
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal(prev => !prev);

  React.useEffect(() => {
    setFirstDate(firstDateOfMonth(new Date()));
  }, []);

  React.useEffect(() => {
    if(firstDate){
      api.get(addDays(-7, firstDate)).then(res => {
        const sortedBookings = sort(byBookingDateTime, res.data);
        setAllBookings(sortedBookings);
        setAllBookingDates(map(d => new Date(d.bookingDate), sortedBookings));
      });
    }
  }, [firstDate]);

  React.useEffect(() => {
    const todayBookings = filter(d => formatDate(d.bookingDate) === formatDate(dateStart), allBookings);
    setBookings(todayBookings);

    if(todayBookings.length){
      setSelected(head(todayBookings));
    }

    const chagnedFirstDate = firstDateOfMonth(dateStart);
    if(formatDate(firstDate) !== formatDate(chagnedFirstDate))
    {
      setFirstDate(chagnedFirstDate);
    }

  }, [dateStart, allBookings]);

  const handleChangeDateStart = date => {
    setDateStart(date);
    setBookings([]);
    setSelected(null);
  };

  const isToday = date => formatDate(date) === formatDate(new Date());

  return (
    <div className="mb-20">
      <div className="flex justify-end w-full mb-3">
        <ButtonPrimary className="btn-sm" onClick={toggleModal}>Change password</ButtonPrimary>
        <ButtonDanger className="btn-sm ml-3" onClick={() => logout()}>Sign out</ButtonDanger>
      </div>
      <Grid>
        <div>
          <h3 className="mb-5">Bookings: {formatDate(dateStart)}{isToday(dateStart) && <span className="text-sm text-blue align-text-top"> *Today</span>}</h3>
          <div className="flex justify-start mb-5">
            <Calendar
              value={dateStart}
              onChange={handleChangeDateStart}
              highlights={allBookingDates}
              startOfWeek={0}
            />
          </div>
          <BookingList data={bookings} selected={selected} onSelect={setSelected} />
        </div>
        <div>
        {
          selected && <BookingDetail data={selected} />
        }
        </div>
      </Grid>
      <ModalBox show={showModal} onClose={toggleModal} style={style.modal} closeStyle={style.modalClose}>
        <ChangePassword />
      </ModalBox>
    </div>
  );
}
