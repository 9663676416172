import React from 'react';
import Modal from 'react-modal';

import Icon from './Icon';

export default function ModalBox({show, style, closeStyle, children, onClose}) {
  return (
    <div style={{zIndex: 1000}}>
      <Modal isOpen={show} style={style ? {content: style} : undefined}>
        <div className="flex justify-center relative">
            {children}
            <button className="fixed top-6 right-10 z-50" style={closeStyle} onClick={onClose}><Icon name="close"/></button>
        </div>
      </Modal>
    </div>
  )
}
