import React from 'react';
import {map, values} from 'ramda';

import ButtonUpload from './ButtonUpload';

export default function UploadFile({id, files, onChange}) {
  const handleChangeFile = e => {
    const uploadFiles = values(e.target.files);
    onChange(uploadFiles);
  };

  return (
    <>
      <ButtonUpload onChange={handleChangeFile} id={id} />
      <ul>
      {
        map(f => <li key={f.name}>{f.name}</li>, files)
      }
      </ul>
    </>
  )
}
