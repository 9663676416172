import React from 'react';

import PageLayout from './PageLayout';
import ServiceContent from './ServiceContent';
import ServiceDetail from './ServiceDetail';

export default function ServicePage() {
  return (
    <PageLayout 
      imagePath="service"
      pageTitle="Services"
      contentTitle="Mobile Windshield Repair Vancouver"
      renderChild1={ServiceContent}
      renderChild2={ServiceDetail}
    />
  );
}
