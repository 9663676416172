import React from 'react';
import {last, map} from 'ramda';

import css from './Dropdown.module.css';

export default function Dropdown({items, value, onChange, ...otherProps}) {
  const optionItems = React.useMemo(() => {
    if(!items || !items.length){
      return [];
    }
    const lastItem = last(items);

    if(lastItem.value !== undefined){
      return items;
    }
    
    return map(d => ({value: d, text: d}), items);
  }, [items]);

  return (
    <select value={value} onChange={onChange} {...otherProps} className={css.select}>
      {optionItems.map(d => <option key={d.value} value={d.value}>{d.text}</option>)}
    </select>
  );
}
