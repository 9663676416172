import React from 'react';
import {init, last} from 'ramda';
import {NavLink} from 'react-router-dom';

import css from './TopNav.module.css';
import Icon from './Icon';
import Row from './Row';
import IcbcLogo from './IcbcLogo';

const style = {
  icon: {fill: 'white', marginLeft: '10px'}
};

export default function TopNav({items}) {
  const [navItems, lastItem] = React.useMemo(() => {
    return [init(items), last(items)];
  }, [items]);
  return (
    <Row style={style.root}>
      <Row className="bg-white w-full justify-around">
        {navItems.map(item => <NavLink key={item.text} to={item.path} className={css.navItem} activeClassName={css.active}>{item.text}</NavLink>)}
        <IcbcLogo className="hidden md:block" />
      </Row>
      <NavLink to={lastItem.path} activeClassName={css.active} className={css.appointment}>
        <span className="whitespace-nowrap">{lastItem.text} <Icon name="chevron-right-circle" style={style.icon} width="13px" /></span>
      </NavLink>
    </Row>
  )
}
