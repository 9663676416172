import React from 'react';

export default function ButtonUpload({onChange, id="upload-file"}) {
  return (
    <div>
      <input type="file" id={id} hidden multiple onChange={onChange}/>
      <label htmlFor={id} className="inline-block bg-black text-white py-2 px-5 rounded-full cursor-pointer">UPLOAD PHOTO</label>
    </div>
  )
}
