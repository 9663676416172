import React from 'react';

const ModernizrContext = React.createContext();

const ModernizrProvider = ({children}) => {
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    if(window.Modernizr){
      window.Modernizr.on('webp', result => setState(
        {webp: Boolean(result)}
      ));
    }
  }, [children]);

  return <ModernizrContext.Provider value={state}>{children}</ModernizrContext.Provider>;
};

const useModernizr = () => {
  const context = React.useContext(ModernizrContext);
  if(!context){
    throw new Error('useModernizr must be used within a ModernizrProvider');
  }
  return context;
};

export {ModernizrProvider, useModernizr};