const twoDigit = n => n > 9 ? `${n}` : `0${n}`;
export const getDate = (date) => ['string', 'number'].indexOf(typeof(date)) >= 0 ? new Date(date) : date;
export const formatDate = date => {
  const dt = getDate(date);
  return dt ? `${dt.getFullYear()}-${twoDigit(dt.getMonth() + 1)}-${twoDigit(dt.getDate())}` : '';
}
export const formatTime = date => {
  const dt = getDate(date);
  return dt ? `${twoDigit(dt.getHours())}:${twoDigit(dt.getMinutes())}` : '';
};
export const formatDateTime = date => `${formatDate(date)} ${formatTime(date)}`;

export const addDays = (days, now) => {
  var date = new Date(now.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export const addHours = (hours, now) =>{
  var date = new Date(now.valueOf());
  date.setHours(date.getHours() + hours);
  return date;
}

export const firstDateOfMonth = date => {
  const dt = getDate(date);
  return new Date(dt.getFullYear(), dt.getMonth(), 1);
};

export const isToday = date => {
  const dt = getDate(date);
  return formatDate(dt) === formatDate(new Date());
};